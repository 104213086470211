import React from 'react';
import Loader from 'react-loader-spinner';
import Input from './input';

const Optin = ({
  handleClose, show, inputChangeHandler, signupHandler, errorHandler, failedMessage, nameError, emailError, passwordError, passwordMatchError, loading,
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main" id="signup-popup">
        <form style={{ minHeight: '600px', marginBottom: '0' }} onSubmit={(e) => signupHandler(e)}>
          <div style={{ padding: '10% 20%', backgroundColor: '#EAB700', border: '15px ridge #ce1417' }}>
            <button onClick={handleClose} className="btn-optin-close">x</button>
            {failedMessage ? (
              <div style={{
                backgroundColor: '#FF4200', color: '#ffffff', padding: '0.5rem', fontSize: '1rem', border: '1px solid red', fontWeight: '600', marginBottom: '1rem',
              }}
              >
                {failedMessage}
              </div>
            ) : null}
            <h1>Create an Account</h1>
            <Input
              id="name"
              className="signup-input-element"
              type="text"
              name="name"
              label="Name"
              control="input"
              onChange={inputChangeHandler}
              error={nameError}
              errorMessage="Please Enter Your Name"
              onClick={() => { errorHandler('nameError', true); }}
            />
            <br />
            <br />
            <Input
              id="email"
              className="signup-input-element"
              name="email"
              type="email"
              label="Email"
              control="input"
              onChange={inputChangeHandler}
              error={emailError}
              errorMessage="Please Enter A Valid Email"
              onClick={() => { errorHandler('emailError', true); }}
            />
            <br />
            <br />
            <br />
            <Input
              id="password"
              className="signup-input-element"
              type="password"
              name="password"
              label="Password"
              control="input"
              onChange={inputChangeHandler}
              error={passwordError}
              errorMessage="Password is too short"
              onClick={() => { errorHandler('passwordError', true); }}
            />
            <br />
            <br />
            <br />
            <Input
              id="confirm_password"
              className="signup-input-element"
              type="password"
              name="confirm_password"
              label="Confirm Password"
              control="input"
              onChange={inputChangeHandler}
              error={passwordMatchError}
              errorMessage="Password Does Not Match"
              onClick={() => { errorHandler('passwordMatchError', true); }}
            />
            <br />
            <br />
            {loading
              ? (
                <div style={{ textAlign: 'center' }}>
                  <Loader
                    type="Bars"
                    color="#03cea4"
                    height={100}
                    width={100}
                  />
                </div>
              )
              : (
                <button
                  type="submit"
                  className="btn-sign-up-optin"
                >
                  Sign Up
                </button>
              )}
          </div>
        </form>
      </section>
    </div>
  );
};

export default Optin;
