import React, { Component } from 'react'
import Layout from "../components/layout"
import SignUp from "../components/signupPopup"
import './products.css'
import { navigate } from "gatsby"
import { v1 as uuidv1 } from 'uuid';
import { validateEmail } from "../util/validators.js"
import { getUser, setAutoLogout } from "../services/auth"
import { config } from "../components/config"

class ProductsPage extends Component {
  state = {
    show: false,
    signupForm: {
      email: {
        value: '',
        valid: false,
        touched: false,
        // validators: [required, email]
      },
      password: {
        value: '',
        valid: false,
        touched: false,
        // validators: [required, length({ min: 5 })]
      },
      confirm_password: {
        value: '',
        valid: false,
        touched: false,
        // validators: [required, length({ min: 5 })]
      },
      name: {
        value: '',
        valid: false,
        touched: false,
        // validators: [required]
      },
      formIsValid: false,
      nameError: false,
      emailError: false,
      passwordError: false,
      passwordMatchError: false,
      failedMessage: "",
      chosenProduct: "free",
      loading: false,
    }
  };

 showModal = () => {
   this.setState({ show: true });
 };

 hideModal = () => {
   this.setState({ show: false });
 };

 validateForm = () => {
     let hasError = false
     let nameError = false
     let emailError = false
     let passwordError = false
     let passwordMatchError = false
     if (this.state.signupForm.name.value === "") {
       nameError = true
       hasError = true
     }
     if (!validateEmail(this.state.signupForm.email.value)) {
       emailError = true
       hasError = true
     }
     if (this.state.signupForm.password.value.length < 5) {
       passwordError = true
       hasError = true
     }
     if (this.state.signupForm.password.value !== this.state.signupForm.confirm_password.value) {
       passwordMatchError = true
       hasError = true
     }
     this.setState({
       formIsValid: hasError,
       nameError: nameError,
       emailError: emailError,
       passwordError: passwordError,
       passwordMatchError: passwordMatchError
     })
     return !hasError
   }

   signupHandler = async (event) => {
     event.preventDefault();
     this.setState({loading: true})
     if (!this.validateForm()) {
       let popup = document.getElementById('signup-popup')
       if (popup) {
         popup.scrollTo(0, 0);
       }
       this.setState({loading: false})
       return
     }
     console.log(this.state)
     const newId = uuidv1();
     try {
       let response = await fetch(`${config.backend}/user/sign-up`, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({
           id: newId,
           email: this.state.signupForm.email.value,
           name: this.state.signupForm.name.value,
           password: this.state.signupForm.password.value
         })
       })
       let res = await response.json()
       if (res.status === 422) {
         this.setState({failedMessage: res.message})
         let popup = document.getElementById('signup-popup')
         if (popup) {
           popup.scrollTo(0, 0);
         }
         this.setState({loading: false})
         throw new Error("Validation failed. Make sure this email hasn't been used yet.")
       }
       localStorage.setItem('token', res.token);
       localStorage.setItem('userId', res.id);
       localStorage.setItem('name', res.name);
       localStorage.setItem('email', res.email);
       const remainingMilliseconds = 365 * 24 * 60 * 60 * 1000;
       const expiryDate = new Date(
         new Date().getTime() + remainingMilliseconds
       );
       localStorage.setItem('expiryDate', expiryDate.toISOString());
       setAutoLogout(remainingMilliseconds);

       this.setState({loading: false})
       if (this.state.chosenProduct === "annual") {
         navigate('/secure/checkout', {
           state: {
             customer: res.customer,
             id: res.id,
             email: res.email,
           }
         })
       } else if (this.state.chosenProduct === "single") {
         navigate('/secure/checkout-single', {
           state: {
             customer: res.customer,
             id: res.id,
             email: res.email,
           }
         })
       } else {
         navigate('/secure/profile', {
           state: {
             customer: res.customer,
             id: res.id,
             email: res.email
           }
         });
       }
     }
     catch(err) {
       this.setState({loading: false})
       console.log(err)
     }
   };

  inputChangeHandler = (input, value) => {
   this.setState(prevState => {
     let isValid = true;
     const updatedForm = {
       ...prevState.signupForm,
       [input]: {
         ...prevState.signupForm[input],
         valid: isValid,
         value: value
       }
     };
     let formIsValid = true;
     return {
       signupForm: updatedForm,
       formIsValid: formIsValid
     };
   });
  };

  errorHandler = (error, clear) => {
      if (clear) {
        this.setState({
          [error]: false
        })
      } else {
      this.setState({
        [error]: true
      })
    }
  }

 handleClick(product) {
   if (!getUser()) {
     this.setState({
       show: true,
       chosenProduct: product
      });
      return
   }
   if (product === "annual") {
     navigate('/secure/checkout')
   } else if (product === "single") {
     navigate('/secure/checkout-single')
   } else {
     navigate('/secure/profile')
   }
 }
 render(){
     return (
        <Layout>
          <div>
            <main>
              <section className="package" id="plus">
                  <div style={{padding: "2rem"}} onClick={() => {this.handleClick("annual")}}>
                      <h1 className="package__title">One Year Membership</h1>
                      <h2 className="package__badge">RECOMMENDED</h2>
                      <h2 className="package__subtitle">Our most popular bundle. <br /><br /><span className="strikethrough">Regular $48/year</span><br /> <span className="package__subtitle--price">Yours for $24/year!</span></h2>
                      <p className="package__info">Get 1 Year of unlimited access to all our custom games! Build, play, and send unlimited games for only $2/month! billed annually</p>
                  </div>
              </section>
              <section className="package" id="free">
                  <div style={{padding: "2rem"}} onClick={() => {this.handleClick("free")}}>
                      <h1 className="package__title">Our FREE Plan</h1>
                      <h2 className="package__subtitle">Sign up to our newsletter for access to our free tier products.</h2>
                      <p className="package__info">Build our free tier games and share the link with your friends!</p>
                  </div>
              </section>
              <div className="clearfix"></div>
              <section className="package" id="premium">
                  <div style={{padding: "2rem"}} onClick={() => {this.handleClick("single")}}>
                      <h1 className="package__title">Pay Per Game</h1>
                      <h2 className="package__subtitle">Purchase our premium games individually to share with your friends <br /><br /><span className="package__subtitle--price">Yours for $5/Game!</span></h2>
                      <p className="package__info">A great chance to sample our games and purchase only as many as you need to share with friends and family!</p>
                  </div>
              </section>
            </main>
         </div>
         <SignUp show={this.state.show} handleClose={this.hideModal} inputChangeHandler={this.inputChangeHandler} signupHandler={this.signupHandler} errorHandler={this.errorHandler} failedMessage={this.state.failedMessage} nameError={this.state.nameError} emailError={this.state.emailError} passwordError={this.state.passwordError} passwordMatchError={this.state.passwordMatchError} loading={this.state.loading}/>
       </Layout>
    )
  }
}

export default ProductsPage
